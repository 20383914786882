<template>
  <div id="visual">
    <!--顶部-->
    <div class="v-top">
      <div class="title-bottom"></div>
      <div class="back_btn">
        <i class="iconfont icon_back" @click="goBack"></i>
        <i class="iconfont icon_fullscreen" @click="screen"></i>
        <!-- <el-button type="primary" @click="goBack" size="mini">返回</el-button>
        <el-button type="primary" @click="screen" size="mini">全屏</el-button> -->
      </div>
      <div class="title-center"></div>
      <div class="title-top">
        <span @click="hrefTest">
          大亚湾水库雨情监管平台
        </span>
      </div>
      <div class="title-time">
        {{curTime}}
      </div>
    </div>
    <!-- 布局 -->
    <div class="v-container">
      <!-- 左 -->
      <div class="v-left">
        <!--水库-->
        <div class="v-box boxall">
          <div class="title">
            <span>水位库容(水库)</span>
            <div class="choose"></div>
            <span class="dot">●</span>
          </div>
          <div class="value">
            <table>
              <tr>
                <th></th>
                <th>监测点名称</th>
                <th>水位值(米)</th>
                <th>库容(万方)</th>
                <th>采集时间</th>
              </tr>
              <tr v-for="(item,index) of data1" :key="item.uid">
                <td>
                  <span class="data1-td1" :style="{backgroundColor:item.color}">
                    {{index+1}}
                  </span>
                </td>
                <td>{{item.title}}</td>
                <td>{{item.water}}</td>
                <td>{{item.storage}}</td>
                <td>{{item.time}}</td>
              </tr>
            </table>
          </div>
          <div class="foot"></div>
        </div>
        <!--水利设施-->
        <div class="v-box boxall">
          <div class="title">
            <span>水位库容(水利设施)</span>
            <div class="choose"></div>
            <span class="dot">●</span>
          </div>
          <div class="value">
            <table>
              <tr>
                <th></th>
                <th>监测点名称</th>
                <th>水位值(米)</th>
                <th>采集时间</th>
              </tr>
              <tr v-for="(item,index) of data11" :key="item.uid">
                <td>
                  <span class="data1-td1" :style="{backgroundColor:item.color}">
                    {{index+1}}
                  </span>
                </td>
                <td>{{item.title}}</td>
                <td>{{item.water}}</td>
                <td>{{item.time}}</td>
              </tr>
            </table>
          </div>
          <div class="foot"></div>

        </div>
        <!--库容-->
        <div class="v-box boxall">
          <div class="title">
            <span>库容 / 水位</span>
            <div class="choose"></div>
            <span class="dot">●</span>
          </div>
          <div class="value">
            <!--库容柱状图-->
            <EchartGradient :res="storageData" style="width: 50%; height: 100%;float: left;"></EchartGradient>
            <!--水位柱状图-->
            <EchartWater :res="waterData" style="width: 50%; height: 100%;float: right;"></EchartWater>
          </div>
          <div class="foot"></div>
        </div>

      </div>
      <!-- 中 -->
      <div class="v-content">
        <!--雨量-->
        <div class="v-box boxall">
          <div class="title">
            <span>雨量</span>
            <div class="choose"></div>
            <span class="dot">●</span>
          </div>
          <div class="value" style="overflow:scroll">
            <table>
              <tr>
                <th></th>
                <th>监测点</th>
                <th>雨量(mm)</th>
                <th>采集时间</th>
              </tr>
              <tr v-for="(item,index) of data3" :key="item.uid">
                <td>
                  <span class="data1-td1" :style="{backgroundColor:item.color}">
                    {{index+1}}
                  </span>
                </td>
                <td>{{item.title}}</td>
                <td>{{item.rainFall}}</td>
                <td>{{item.time}}</td>
              </tr>
            </table>
          </div>
          <div class="foot"></div>
        </div>
        <!--地图-->
        <div class="v-box">
          <Map :deviceItems="deviceData" :mapData="mapdata"></Map>
        </div>
        <!--风速-->
        <div class="v-box boxall">
          <div class="title">
            <span>风速</span>
            <div class="choose"></div>
            <span class="dot">●</span>
          </div>
          <!-- <div class="title">
					</div> -->
          <div class="value">
            <EchartWindSpeed :res="windSpeed"></EchartWindSpeed>
          </div>
          <div class="foot"></div>
        </div>
      </div>
      <!-- 右 -->
      <div class="v-right">
        <!--内涝设备-->
        <div class="v-box boxall">
          <div class="title">
            <span>内涝设备</span>
            <div class="choose"></div>
            <span class="dot">●</span>
          </div>
          <div ref="scroll" class="scroll">
            <div class="value">
              <div class="tb-div">
                <div class="tb-div-content">监测点名称</div>
                <div class="tb-div-content-center">水位值(米)</div>
                <div class="tb-div-content-center">信号</div>
                <div class="tb-div-content-center">采集时间</div>
              </div>
              <vue-seamless-scroll :data="deviceData" class="seamless-warp" ref="seamlessScroll" :class-option="seamlessOpt" :key="scrollKey">
                <!-- <ul> -->
                <li :class="[hoverIndex ==index ?'hover_li' :'' ,'tb-div-li']" v-for="(item,index) in deviceData" :key="index" :data-item="JSON.stringify(item)" @mouseover="hoverIndex = index" @mouseleave="hoverIndex = null">
                  <div class="deviceItems-td1">{{index + 1}}</div>
                  <div class="tb-div-content-ui" :title="item.title">{{item.title?item.title:item.uid}}</div>
                  <div class="tb-div-content-ui" :title="item.f">{{item.f}}</div>
                  <div class="tb-div-content-ui" :title="item.s">{{item.s}}</div>
                  <div class="tb-div-content-ui" :title="item.time" style="text-align: center;">{{item.time}}</div>
                </li>
                <!-- </ul> -->
              </vue-seamless-scroll>
            </div>
          </div>
          <div class="foot"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Map from '../components/VMapDayawan.vue'; //地图
// import Weather from '../components/VWeather.vue'; //气象数据
// import DeviceCount from '../components/VDeviceCount.vue'; //设备统计
// import RealData from '../components/VRealData.vue'; //设备数据
// import RecentData from '../components/VRecentData.vue'; //近一小时数据
import EchartWindSpeed from '../components/Echart/echartWindSpeed.vue'//风力折线图
import EchartGradient from '../components/Echart/echartGradient.vue'//库容渐变柱状图
import EchartWater from '../components/Echart/echartWater.vue'//水位柱状图
// import SeamlessScroll from '../components/scroll/SeamlessScroll.vue'

import EchartsTheme from '../common/echarts_theme.js'; // echarts主题

export default {
  data() {
    return {
      hourData: 'PM2.5',
      data1: '', //水位监测
      data11: '', //水位监测
      windSpeed: {}, //水位图表数据
      data3: '', //水质信息
      storageData: [],//库容柱状图
      waterData: [],//水位柱状图
      timer: null,
      curTime: '',
      customTitle: '',
      hourCache: -1,
      deviceData: [],
      hoverIndex: null, // 浮动索引
      scrollKey: 0,
      mapdata: {   // 大亚湾小地图
        center: {
          lng: 114.54042,
          lat: 22.741633,
        },
        zoom: 13
      }
    }
  },
  components: {
    Map,
    // Weather,
    // DeviceCount,
    // RealData,
    // RecentData,
    EchartWindSpeed, EchartGradient, EchartWater,
  },
  watch: {
    curTime: {
      handler() {
        return new Date();
      }
    }
  },
  methods: {
    goBack() {
      // window.history.back();
      this.$router.push({ path: '/public/live_data' })

    },
    screen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },

    testDyw() {
      let json = {
        "cmd": "daYaWanDashboard"
      }
      this.ws.send(json);

    },

    // 设置图表主题——每个子组件都要设置一遍，待处理
    setEchartsTheme() {
      let theme = EchartsTheme.getEchartsTheme();
      this.echarts.registerTheme('echarts_darken', theme);
    },
    sensorItemClick(item) {
      this.hourData = item.title;
      this.$refs.recentData.drawLine(item);
    },

    // 以下都是模拟数据
    // 水位监测


    hrefTest() {
      //window.location.href ="https://5xp2op.axshare.com/#g=1&p=%E6%B0%B4%E7%94%B5%E7%94%9F%E7%94%9F%E6%80%81%E6%B5%81%E9%87%8F%E7%AE%A1%E7%90%86%E7%B3%BB%E7%BB%9F&c=1";
    },
    fetchDashboard() {
      let json = {
        "cmd": "daYaWanDashboard"
      }
      this.ws.send(json);
    },
    //获取全部设备
    dataRequest() {
      let json = {
        "cmd": "devices"
      }
      this.ws.send(json);
      this.ws.addCallback('devicesAck', (res) => {
        //过滤出内涝数据
        // console.log("设备数据：", res);
        this.deviceData = [] //有新数据时复位内涝数组
        res.devices.forEach(item => {
          if (item.model == 'dmWater') {
            this.deviceData.push({ 'uid': item.uid, 'title': item.title, 'model': item.model })
          }
        })
        this.setHotDevices() //设置内涝设备为热点设备
      })
      this.getData()
    },

    //接收实时值
    getData(obj) {
      this.ws.addCallback('event', (res) => {
        // console.log("大屏接收res数据", res);

        if (res.type != "sensor") {
          return;
        } else {
          let uidData = this.getDevice(res.uid);
          let data;
          if (uidData != null) {
            data = this.parseProfile.parseData2(uidData.model, res.data ? res.data : "");
            // console.log("大屏接收res数据", data[0], data);

            this.deviceData.forEach((item, index) => {
              if (item.uid == res.uid && data.length > 0) {
                let f_key = data[0].findIndex(val => { return val.key === "f" })
                let s_key = data[0].findIndex(val => { return val.key === "s" })
                this.deviceData[index].f = data[0] ? data[0][f_key].value : ''
                this.deviceData[index].s = data[0] ? data[0][s_key].value : ''
                this.deviceData[index].time = this.getTime()
              }
            })
          }
        }
      })
    },
    // 设置热点设备
    setHotDevices() {
      let uids = "";
      this.deviceData.forEach((item) => {
        uids += item.uid + ",";
      })
      let json = {
        "cmd": "setHotDevices",
        "uids": uids
      }
      this.ws.send(json);
    },
    getDevice(uid) {
      let obj = this.common.cFindObj(this.deviceData, "uid", uid);
      return obj;
    },
    getTime() {
      var myDate = new Date();
      return myDate.getMonth() + 1 + '.' + myDate.getDate() + ' ' + myDate.getHours() + ':' + myDate.getMinutes()
    }
  },
  mounted() {
    this.setEchartsTheme();
    this.fetchDashboard();
    this.dataRequest();//请求所有设备数据
    this.ws.addCallback('jsonChannelReady', (res) => {
      this.fetchDashboard();
    })


    this.timer = setInterval(() => {
      let date = this.common.cGetDate('-');
      let time = this.common.cGetTime(':');
      this.curTime = date + " " + time;


      {
        let date = new Date();
        let hour = date.getHours();
        if (this.hourCache == -1 || this.hourCache != hour) {
          console.log("auto fetchDashboard");
          this.hourCache = hour;
          this.fetchDashboard();
        }

      }

      if (this.$refs.seamlessScroll) {  //避免页面不在大屏时获取不到滚动，报错
        // console.log('滚动刷新');
        this.$refs.seamlessScroll.reset();//触发子组件中的重置方法
      }
    }, 1000)

    let logo = this.common.cGetLogo();
    this.customTitle = logo.wallTitle;

    this.ws.addCallback('daYaWanDashboardAck', (res) => {
      let devices = res.devices;
      // console.log("devices=", devices);

      this.windSpeed = res  //风力子组件传入数据

      {
        let items = devices.filter(item => item.title.indexOf("水库") != -1);
        this.data1 = items; //水位库容(水库)

        let items2 = devices.filter(item => item.title.indexOf("水库") == -1);
        this.data11 = items2;//水位库容(水利设施)
      }
      this.data3 = devices; //风速折线图
      this.storageData = this.data1; //库容渐变柱状图
      this.waterData = devices;//水位柱状图
    });
  },
  computed: {
    seamlessOpt() {   //滚动参数设置
      return {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 20, // 开始无缝滚动的数据量 
        openWatch: true, // 开启数据实时监控刷新dom
      }
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
</script>

<style lang="scss">
@import "../assets/iconfont/icon_dot.css";
@import "../assets/iconfont/icon_back.css";
@import "../assets/iconfont/icon_fullscreen.css";
body {
  background-color: #010101;
  background: #000d4a url(../assets/visual_bg.jpg);
  background-size: cover;
  color: #dddddd;
  font-size: 12px;
}

#visual {
  $top-h: 65px;
  $border_color: #077ccb;
  $title_color: #0c72f8;
  $boxborder_color: #02a6b5; //折角颜色
  $boxborder_wh: 0.7rem; //边框折角长度
  $boxback_color: #0c216b; //box框背景色
  $container_fontsize: calc(100vw / 135); //box字体大小calc(100vw / 110)
  .iframe {
    width: 100%;
    height: calc(100vh - 3px);
  }

  // 顶部标题
  .v-top {
    height: $top-h;
    box-sizing: border-box;
    text-align: center;
    font-size: 20px;
    // border-bottom: 1px solid #28567A;
    // box-shadow: 0 1px 8px #28567A;
    // text-shadow: 1px 1px 0 $border_color;

    .title-bottom {
      height: 45px;
      background: #091838;
    }

    .title-center {
      // width: 600px;
      width: 50%;
      height: 0;
      border-top: 65px solid #0a1b3e;
      border-left: 80px solid transparent;
      border-right: 80px solid transparent;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      filter: drop-shadow(0 1px 4px #2490eb);
    }

    .title-top {
      height: 65px;
      font-size: 40px;
      letter-spacing: 5px;
      font-weight: bold;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      filter: drop-shadow(2px 2px 2px #000000);
      display: -webkit-box;
      -webkit-box-pack: center;
      -webkit-box-align: center;
      background-image: -webkit-gradient(
        linear,
        0 0,
        0 bottom,
        from(#2490eb),
        to(#48cbff)
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .title-time {
      color: rgba(255, 255, 255, 0.7);
      position: absolute;
      top: 12px;
      right: 12px;
      font-family: "微软雅黑";
    }
    .back_btn {
      position: absolute;
      top: 12px;
      left: 12px;
      z-index: 999;
      color: #0a87e9;
      .icon_back {
        font-size: 24px;
        padding: 0 10px;
      }
      .icon_back:hover,
      .icon_fullscreen:hover {
        color: #6b9cff;
      }
      .icon_fullscreen {
        font-size: 24px;
        padding: 0 10px;
      }
    }
  }

  .v-title {
    position: absolute;
    width: 450px;
    top: 20px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 5px;
    border-radius: 50%;
    // background-image: -webkit-gradient(linear, 0 0, 0 bottom, from(#2490EB), to(#42B8EA));
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    background-color: #010101;
    z-index: 1;
  }

  // 布局
  .v-container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - #{$top-h} - 20px);
    // background-color: #050c1c;
    margin: 10px;
    box-sizing: border-box;
    font-size: $container_fontsize;
    //边框上折角
    .boxall::after,
    .boxall::before {
      position: absolute;
      width: $boxborder_wh;
      height: $boxborder_wh;
      content: "";
      border-top: 2px solid $boxborder_color;
      top: 0;
    }
    .boxall::before {
      border-left: 2px solid $boxborder_color;
      left: 0;
    }
    .boxall::after {
      border-right: 2px solid $boxborder_color;
      right: 0;
    }
    //边框下折角
    .foot::after,
    .foot::before {
      position: absolute;
      width: $boxborder_wh;
      height: $boxborder_wh;
      content: "";
      border-bottom: 2px solid $boxborder_color;
      bottom: 0;
    }
    .foot::before {
      border-left: 2px solid $boxborder_color;
      left: 0;
    }
    .foot::after {
      border-right: 2px solid $boxborder_color;
      right: 0;
    }
    // 左
    .v-left {
      flex: 0.33;
      display: flex;
      flex-direction: column;

      .v-box {
        border: 1px solid rgba(25, 186, 139, 0.17);

        &:nth-child(1) {
          flex: 0.33;
        }
        &:nth-child(2) {
          flex: 0.34;
        }
        &:nth-child(3) {
          flex: 0.33;
          overflow: auto;
        }
      }
    }

    // 中
    .v-content {
      flex: 0.34;
      display: flex;
      flex-direction: column;
      .v-box {
        border: 1px solid rgba(25, 186, 139, 0.17);

        &:nth-child(1) {
          flex: 0.33;
        }
        &:nth-child(2) {
          flex: 0.34;
        }
        &:nth-child(3) {
          flex: 0.33;
          overflow: auto;
        }
      }
    }

    // 右
    .v-right {
      flex: 0.33;
      display: flex;
      flex-direction: column;
      border: 1px solid rgba(25, 186, 139, 0.17);

      .scroll {
        .value {
          //标题
          .tb-div {
            height: 30px;
            // font-size: 18px;
            display: inline-flex;
            width: 100%;
            .tb-div-content {
              width: 25%;
              text-align: center;
              padding-left: 20px;
              color: #6fb5ba;
              border: 1px solid #4d61a1;
              border-top-style: none;
              border-left-style: none;
              border-right-style: none;
            }
            .tb-div-content-center {
              width: 25%;
              text-align: center;
              color: #6fb5ba;
              border: 1px solid #4d61a1;
              border-top-style: none;
              border-left-style: none;
              border-right-style: none;
            }
          }
          //列表
          .seamless-warp {
            overflow: hidden;
            height: calc((182vh - 120px) / 2); //84vh;
            li {
              // margin: 15px 0;
              font-size: $container_fontsize;
              text-align: left;
              cursor: pointer;
              span {
                font-weight: normal;
                vertical-align: middle;
                color: #333;
              }

              .font-blue {
                color: #06f;
              }

              .font-white {
                color: white;
              }

              .date {
                float: right;
                color: #999;
              }

              &:first-child {
                margin-top: 0;
              }

              // img {
              //     width: 20px;
              //     margin-right:10px;
              //     vertical-align: middle;
              // }
            }
            .hover_li {
              background: #4d61a1;
            }
            .tb-div-li {
              border: 1px solid #4d61a1;
              border-top-style: none;
              border-left-style: none;
              border-right-style: none;
              height: 40px;
              font-size: $container_fontsize;
              display: inline-flex;
              width: 100%;
            }
            .deviceItems-td1 {
              display: inline-block;
              width: 12px;
              height: 15px;
              // line-height: 14px;
              padding: 5px;
              margin-top: 11px;
              color: white;
            }
            .tb-div-content-ui {
              // border: 1px solid #4d61a1;
              // border-top-style: none;
              // border-left-style: none;
              // border-right-style: none;
              width: 25%;
              text-align: center;
              color: #dedede;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              // padding: 3px;
              margin-top: 14px;
              margin-left: 2px;
            }
          }
        }
      }
      .v-box {
        flex: 1;
      }
    }
  }

  // 盒子
  .v-box {
    $border_color2: #49c7d9;

    min-width: 280px;
    min-height: 100px;
    // background-color: lighten($color: #050c1c, $amount: 2%);
    background: rgba(255, 255, 255, 0.04) url(../assets/line.png);
    background-repeat: no-repeat;
    // background-size: 2px 12px, 20px 2px;
    background-size: 100% auto;
    position: relative;
    margin: 4px;
    padding: 5px;
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    // 标题
    .title {
      font-size: 15px;
      font-weight: bold;
      padding: 0 8px 10px 8px;
      height: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .choose {
        flex: auto;
      }

      .dot {
        font-size: 16px;
        color: #0a87e9;
      }
    }

    // 数据
    .value {
      flex: auto;
    }
  }

  // 自定义滚动条
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: darken($color: $border_color, $amount: 15%);
  }

  ::-webkit-scrollbar-track {
    border-radius: 12px;
  }

  table {
    width: 100%;
    height: 100%;
    color: #dedede;
    border-spacing: 0;

    tr th {
      color: #6fb5ba;
    }

    tr th,
    tr td {
      padding: 5px;
      text-align: center;
      border-bottom: 1px solid #4d61a1;
    }

    .data1-td1 {
      display: inline-block;
      width: 15px;
      line-height: 14px;
      padding-top: 2px;
    }
    .data11-td1 {
      display: inline-block;
      width: 15px;
      line-height: 14px;
      padding-top: 2px;
    }

    .data3-td1 {
      color: #1274e6;
    }
  }
}
</style>
